import React, { useState } from 'react';
import { Box } from 'theme-ui';

import TextInput from '../TextInput/TextInput';
import PasswordConstraint from '../PasswordConstraint/PasswordConstraint';

interface PasswordInputProps {
  id: string;
  name: string;
  placeholder: string;
  showValidation?: boolean;
  required?: boolean;
  overlayLink?: React.ReactElement | null;
  error?: React.ReactNode;
  onChange: (value: string) => void;
  disabled?: boolean;
}

export interface ValidationConstraints {
  [key: string]: {
    desktopTitle: string;
    mobileTitle: string;
    regex: RegExp;
  };
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  id,
  name,
  placeholder,
  required = false,
  showValidation = false,
  overlayLink = null,
  error = null,
  disabled = false,
  onChange,
}: PasswordInputProps) => {
  const [hasTyped, setHasTyped] = useState(false);
  const [validation, setValidation] = useState<{ [key: string]: boolean }>({
    length: false,
    uppercase: false,
    lowercase: false,
    email: false,
  });

  const validationConstraints: ValidationConstraints = {
    length: {
      desktopTitle: 'MINIMAL_CHAR_LONG',
      mobileTitle: 'PASS_TO_SHORT',
      regex: /.{6,}/,
    },
    uppercase: {
      desktopTitle: 'CAPITAL_LETTER_REQUIRED',
      mobileTitle: 'CAPITAL_LETTER_REQUIRED',
      regex: /[A-Z]+/,
    },
    lowercase: {
      desktopTitle: 'LOWERCASE_REQUIRED',
      mobileTitle: 'LOWERCASE_REQUIRED',
      regex: /[a-z]+/,
    },
    email: {
      desktopTitle: 'EMAIL_VALIDATION',
      mobileTitle: 'EMAIL_VALIDATION',
      regex: /^[^@\s]+@stretchme\.pl$/,
    },
  };

  if (overlayLink && showValidation) {
    throw new Error(
      'PasswordInput can not support showValidation and overlayLink at the same time',
    );
  }

  const revalidate = (value: string): void => {
    setHasTyped(true);
    setValidation(
      Object.keys(validationConstraints).reduce((prev, index) => {
        return {
          ...prev,
          [index]: validationConstraints[index].regex.exec(value) !== null,
        };
      }, {}),
    );
  };

  return (
    <div>
      <TextInput
        disabled={disabled}
        type="password"
        id={id}
        name={name}
        onChange={(e): void => {
          revalidate(e.target.value);
          onChange(e.target.value);
        }}
        required={required}
        placeholder={placeholder}
        overlayLink={overlayLink}
        error={error}
        sx={showValidation ? { mb: 0 } : {}}
      />

      {showValidation && (
        <Box
          sx={{
            mt: '-1.5rem',
            mb: 5,
            visibility: hasTyped ? 'visible' : 'hidden',
          }}
        >
          {Object.keys(validation).map((index) => (
            <PasswordConstraint
              key={index}
              mobile={false}
              isValid={validation[index]}
              title={validationConstraints[index].desktopTitle}
            />
          ))}
        </Box>
      )}
    </div>
  );
};

export default PasswordInput;
