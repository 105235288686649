import { Button, ButtonProps } from 'theme-ui';
import theme from '../../../gatsby-plugin-theme-ui';

interface CustomButtonProps {
  isHeading?: boolean;
}

const Style = {
  base: {
    fontFamily: theme.fonts.heading,
  },
};

export default function (props: ButtonProps & CustomButtonProps) {
  return (
    <Button {...props} style={{ ...props.style, ...Style.base }}>
      {props.children}
    </Button>
  );
}
