import * as React from 'react';
import ReactDOM from 'react-dom';
import { Box, Image } from 'theme-ui';

import BtnClose from '../../../assets/btnClose.svg';

interface OverlayModalProps {
  allowClose?: boolean;
  children: (
    hide: (callback?: () => void) => void,
    rendered: boolean,
  ) => React.ReactElement | null;
  hideBackdrop?: boolean;
  onClose?: () => void;
  portalSelector?: string;
  visible?: boolean;
  maxWidth?: number;
}

const OverlayModal: React.FC<OverlayModalProps> = ({
  allowClose = false,
  children,
  hideBackdrop = false,
  onClose,
  portalSelector,
  visible = true,
  maxWidth = 600,
}: OverlayModalProps) => {
  const [rendered, setRendered] = React.useState(false);

  const hideModal = (callback?: () => void): void => {
    setRendered(false);
    if (callback) {
      setTimeout(callback, 300);
    }
  };

  React.useEffect(() => {
    if (visible && !rendered) {
      // setTimeout to render it first with `rendered = false` and then setting `rendered` to true
      // this allows us to make fancy animations when the modal is displayed
      setTimeout(() => {
        setRendered(true);
      }, 0);
    } else {
      setRendered(visible);
    }
  }, [visible]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const onKeyDown = (e: KeyboardEvent): void => {
      if (e.key === 'Escape' || e.keyCode === 27) {
        hideModal(onClose);
      }
    };

    if (allowClose) {
      window.addEventListener('keydown', onKeyDown);
    }

    return (): void => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [allowClose, onClose]);

  const content = (
    <Box
      sx={{
        position: 'fixed',
        top: '3rem',
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 9,
        backgroundColor: !hideBackdrop
          ? ['#ffffff', 'rgba(255, 255, 255, 0.9)']
          : undefined,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 0,
        transition: 'opacity 0.3s ease-in',
        overflow: 'scroll',

        '@media (orientation: portrait)': {
          top: '3.75rem',
        },

        '@media (orientation: portrait) and (min-width: 48em)': {
          top: '5rem',
        },

        '@media (orientation: landscape) and (min-height: 48em)': {
          top: '3.75rem',
        },

        '@media (orientation: landscape) and (min-height: 62em)': {
          top: '5rem',
        },
      }}
      style={{
        opacity: visible ? 1 : 0,
        pointerEvents: visible ? 'all' : 'none',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          maxWidth: `${maxWidth}px`,
          maxHeight: '100%',
          py: 6,
          px: ['20px', 6],
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: ['0', '0px 10px 16px 0px rgba(0, 0, 0, 0.1)'],
          textAlign: 'center',
          transition: 'opacity 0.3s ease-in, transform 0.3s ease-in',
          opacity: rendered ? 1 : 0,
          transform: rendered ? 'translateY(0)' : 'translateY(-20px)',
          '@media (max-width: 320px)': {
            py: 3,
          },
        }}
      >
        {allowClose && (
          <Image
            src={BtnClose}
            sx={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              cursor: 'pointer',
              '@media (max-width: 374px)': {
                top: '10px',
                height: '20px',
                width: '20px',
              },
            }}
            onClick={(): void => {
              hideModal(onClose);
            }}
          />
        )}
        {children(hideModal, rendered)}
      </Box>
    </Box>
  );

  if (typeof document !== 'undefined' && portalSelector) {
    // React does *not* create a new div. It renders the children into `domNode`.
    // This is useful when we need to place the overlay in different places to overlap any other elements
    // `portalSelector` can be anything that returns exactly one domNode
    const domNode = document.querySelector(portalSelector);

    if (!domNode) {
      // maybe the portal-container is not yet loaded
      return null;
    }

    return ReactDOM.createPortal(content, domNode);
  }

  return content;
};

export default OverlayModal;
