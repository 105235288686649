/** @jsxImportSource theme-ui */

import styled from 'styled-components';

import AuthBox, { AuthVariant } from '../../02_molecules/AuthBox/AuthBox';
import Container from '../../01_atoms/Container/Container';
import LoginTeaser from '../../../assets/images/teaserImageLogin.jpg';
import theme from '../../../gatsby-plugin-theme-ui';
import { getDeviceWidth } from '../../../utils/getDeviceWidth';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  @media (max-width: ${getDeviceWidth.laptop}) {
    justify-content: center;
    flex-direction: column;
  }
`;
const LeftContainer = styled.div`
  text-align: left;
`;
const RightContainer = styled.div`
  text-align: right;
  margin-left: 10rem;
`;
const GrayWrapper = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  margin-top: 120px;
  left: 50%;
  right: 0;
  height: auto;
  bottom: 0;
  background-color: ${theme.colors.grey1};
  display: block;
  transition: filter ease-in 0.3s;

  display: none;
  @media (min-width: ${getDeviceWidth.laptopL}) {
    display: block;
  }
`;
const ImageStyled = styled.img`
  margin-top: 80px;
  margin-left: 16px;
  max-width: 100%;
  @media (max-width: ${getDeviceWidth.laptop}) {
    display: none;
  }
`;

const AuthSection = ({ variant = 'signin' }: { variant?: AuthVariant }) => {
  return (
    <>
      <Container>
        <Wrapper>
          <LeftContainer>
            <AuthBox variant={variant} />
          </LeftContainer>

          <RightContainer>
            <ImageStyled src={LoginTeaser} />
          </RightContainer>
        </Wrapper>
        <GrayWrapper />
      </Container>
      <div id="overlay-modal" />
    </>
  );
};

export default AuthSection;
