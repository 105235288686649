import React from 'react';

import { AuthVariant } from './AuthBox';
import Button from '../../01_atoms/Button/Button';
import PasswordInput from '../../01_atoms/PasswordInput/PasswordInput';
import TextInput from '../../01_atoms/TextInput/TextInput';

type ConfirmResetPasswordProps = {
  confirmCode?: string;
  errorInformation: null | {
    position: string;
    element: React.ReactNode;
  };
  setCode: (v: string) => void;
  setCurrentVariant: (variant: AuthVariant) => void;
  setPassword: (v: string) => void;
};

export default function ConfirmResetPassword({
  confirmCode,
  errorInformation,
  setCode,
  setCurrentVariant,
  setPassword,
}: ConfirmResetPasswordProps) {
  return (
    <>
      <React.Fragment key="confirm-submit-fragment">
        <TextInput
          id="code"
          type="text"
          name="code"
          onChange={(e): void => {
            setCode(e.target.value);
          }}
          required
          placeholder={'CODE_FROM_EMAIL'}
          defaultValue={confirmCode}
          error={
            errorInformation?.position === 'code' && errorInformation.element
          }
          style={{ marginTop: '3.5rem' }}
        />

        <PasswordInput
          id="password"
          name="password"
          onChange={setPassword}
          placeholder={'PASSWORD'}
          showValidation={true}
          required
          error={
            errorInformation?.position === 'password' &&
            errorInformation.element
          }
        />

        <Button
          type="submit"
          style={{
            cursor: 'pointer',
            display: 'block',
            marginTop: '3rem',
          }}
        >
          {'BEST_TIGEN'}
        </Button>

        <Button
          type="button"
          variant="text"
          style={{ marginTop: '2rem', color: 'black' }}
          onClick={(): void => setCurrentVariant('signin')}
        >
          {'ZUR_CK_ZUM_LOGIN'}
        </Button>
      </React.Fragment>
    </>
  );
}
