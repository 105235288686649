import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthVariant } from './AuthBox';
import Button from '../../01_atoms/Button/Button';
import PasswordInput, {
  ValidationConstraints,
} from '../../01_atoms/PasswordInput/PasswordInput';
import TextInput from '../../01_atoms/TextInput/TextInput';

type SignInFormProps = {
  errorInformation: null | {
    position: string;
    element: React.ReactNode;
  };
  setEmail: (v: string) => void;
  setPassword: (v: string) => void;
  setIsValidEmail: (v: boolean) => void;
  setCurrentVariant: (variant: AuthVariant) => void;
  loading: boolean;
  disabled: boolean;
};

const Style = {
  socialMediaButton: {
    cursor: 'pointer',
    marginTop: '0.5rem',
    backgroundColor: 'rgb(243, 243, 243)',
    flexDirection: 'row' as CSSProperties['flexDirection'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    maxHeight: 47,
  },
  buttonIcon: {
    width: '8%',
  },
  buttonText: {
    fontWeight: 'bold',
    width: '92%',
  },
  loginButton: {
    cursor: 'pointer',
    marginTop: '3rem',
    display: 'block',
    alignSelf: 'flex-end',
  },
};

const validationConstraints: ValidationConstraints = {
  email: {
    desktopTitle: 'EMAIL_VALIDATION',
    mobileTitle: 'EMAIL_VALIDATION',
    regex: /^[^@\s]+@stretchme\.pl$/,
  },
};

export default function SignInForm({
  errorInformation,
  setEmail,
  setPassword,
  setIsValidEmail,
  setCurrentVariant,
  disabled,
  loading,
}: SignInFormProps) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <TextInput
        id="email"
        type="text"
        name="email"
        placeholder={t('EMAIL_ADDRESS')}
        required
        onChange={(e): void => {
          const isValid = validationConstraints.email.regex.test(
            e.target.value,
          );
          setIsValidEmail(!isValid);
          setEmail(e.target.value);
        }}
        error={
          errorInformation?.position === 'email' && errorInformation.element
        }
        disabled={loading}
      />

      <PasswordInput
        id="password"
        name="password"
        onChange={setPassword}
        placeholder={t('PASSWORD')}
        required
        overlayLink={
          <Button
            type="button"
            variant="textGrey"
            sx={{ fontSize: 1 }}
            onClick={(): void => setCurrentVariant('resetPassword')}
          >
            {t('VERGESSEN')}
          </Button>
        }
        error={
          errorInformation?.position === 'password' && errorInformation.element
        }
        disabled={loading}
      />

      <Button
        type="submit"
        style={Style.loginButton}
        disabled={loading || disabled}
      >
        {t('EINLOGGEN')}
      </Button>
    </React.Fragment>
  );
}
