import * as React from 'react';
import { navigate } from 'gatsby';

import Header from '../components/02_molecules/Header/Header';
import AuthSection from '../components/03_organisms/AuthSection/AuthSection';
import useAuth from '../hooks/useAuth';

import Page from '../components/04_templates/Page';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';

function LoginPage() {
  const { isSignedIn, isInitialized } = useAuth();

  React.useEffect(() => {
    if (isInitialized && isSignedIn) {
      navigate(`/app`, { replace: true });
    }
  }, [isInitialized, isSignedIn]);

  return (
    <Page title={'Login'} authRequired={false}>
      <LoginPageContent />
    </Page>
  );
}

function LoginPageContent() {
  return (
    <>
      <Header
        theme="light"
        backLinkOverride="https://www.app.stretchme.app/"
        showConnectButton={true}
      />
      <AuthSection />
    </>
  );
}

export default LoginPage;
