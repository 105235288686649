const gtagReportConversion = async (): Promise<void> => {
  /* eslint-disable */
  if (typeof gtag === 'undefined') {
    return Promise.resolve();
  }

  return new Promise((resolve) => {
    gtag('event', 'conversion', {
      send_to: 'AW-644934357/huZ9CNaemOABENXVw7MC',
      event_callback: resolve,
    });
  });
  /* eslint-enable */
};

export default gtagReportConversion;
